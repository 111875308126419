import React, { useState, useEffect } from 'react';
import { string, element, func, bool } from 'prop-types';

import './ExpandableContent.scss';

const ExpandableContent = ({
  expandToggleTitle,
  collapseToggleTitle,
  children,
  onChange,
  defaultIsOpen,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(isOpen);
    }
  }, [isOpen, onChange]);

  const toggleClassName = isOpen ? 'collapse-toggle' : 'expand-toggle';

  return (
    <div className="expandable-content">
      <button
        type="button"
        onClick={() => setIsOpen((prevState) => !prevState)}
        className={`expandable-content__toggle ${toggleClassName}`}
      >
        {isOpen ? collapseToggleTitle : expandToggleTitle}
      </button>
      {isOpen && children}
    </div>
  );
};

ExpandableContent.propTypes = {
  expandToggleTitle: string,
  collapseToggleTitle: string,
  children: element,
  onChange: func,
  defaultIsOpen: bool,
};

ExpandableContent.defaultProps = {
  expandToggleTitle: '',
  collapseToggleTitle: '',
  children: <></>,
  onChange: () => {},
  defaultIsOpen: false,
};

export default ExpandableContent;
