import React, { useRef, useEffect } from 'react';
import { number, func, object, bool, array, string } from 'prop-types';

import Menu from '../Menu/Menu';
import Exhibitions from '../Exhibitions/Exhibitions';
import About from '../About/About';
import EventsWrapper from '../EventsWrapper/EventsWrapper';
import Header from '../Header/Header';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

import './Main.scss';

const Main = ({
  about,
  exhibitions,
  selectedEventId,
  setSelectedEventId,
  selectedExhibitionId,
  setSelectedExhibitionId,
  setIsSidebarOpen,
  isSelectedAbout,
  isSelectedEvent,
  setIsSelectedAbout,
  isSelectedLogo,
  setIsSelectedLogo,
  setIsSelectedEvent,
  currentExhibitions,
  upcomingExhibitions,
  previousExhibitions,
  currentEvents,
  previousEvents,
  upcomingEvents,
  language,
  setLanguage,
  staticText,
}) => {
  const ref = useRef();
  useEffect(() => {
    if (isSelectedLogo) {
      ref.current.scrollTop = -113;
    }
    setIsSelectedLogo(false);
  });

  return (
    <main className="main">
      <section className="main__menu-container">
        <Header setIsSelectedLogo={setIsSelectedLogo} />
        <Menu
          currentExhibitions={currentExhibitions}
          upcomingExhibitions={upcomingExhibitions}
          previousExhibitions={previousExhibitions}
          upcomingEvents={upcomingEvents}
          handleSelectExhibition={setSelectedExhibitionId}
          handleSelectEvent={setSelectedEventId}
          handleSelectEvents={setIsSelectedEvent}
          setIsSidebarOpen={setIsSidebarOpen}
          handleSelectAbout={setIsSelectedAbout}
          staticText={staticText}
        />
      </section>

      <div ref={ref} className="main__content-container">
        <LanguageSwitcher
          language={language}
          setLanguage={setLanguage}
          hideOnMobile="hideOnMobile"
        />

        <section className="main__exhibitions-list-container">
          <div>
            {currentExhibitions.map((exhibition) => (
              <Exhibitions
                key={exhibition.id}
                id={exhibition.id}
                selectedExhibitionId={selectedExhibitionId}
                setSelectedExhibitionId={setSelectedExhibitionId}
                name={exhibition.name}
                title={exhibition.title}
                type={exhibition.type}
                dateFrom={exhibition.dateFrom}
                dateTo={exhibition.dateTo}
                description={exhibition.description}
                subHeader={exhibition.subHeader}
                descriptionBlock2={exhibition.descriptionBlock2}
                subDescription={exhibition.subDescription}
                listOfPeople={exhibition.listOfPeople}
                pictures={exhibition.pictures}
                pdfFiles={exhibition.pdfFiles}
                staticText={staticText}
                links={exhibition.link}
                language={exhibition.language}
                galleryFiles={exhibition.galleryFiles}
              />
            ))}

            {upcomingExhibitions.map((exhibition) => (
              <Exhibitions
                key={exhibition.id}
                id={exhibition.id}
                selectedExhibitionId={selectedExhibitionId}
                setSelectedExhibitionId={setSelectedExhibitionId}
                name={exhibition.name}
                title={exhibition.title}
                type={exhibition.type}
                dateFrom={exhibition.dateFrom}
                dateTo={exhibition.dateTo}
                description={exhibition.description}
                subHeader={exhibition.subHeader}
                descriptionBlock2={exhibition.descriptionBlock2}
                subDescription={exhibition.subDescription}
                listOfPeople={exhibition.listOfPeople}
                pictures={exhibition.pictures}
                pdfFiles={exhibition.pdfFiles}
                staticText={staticText}
                links={exhibition.link}
                language={exhibition.language}
                galleryFiles={exhibition.galleryFiles}
              />
            ))}

            {previousExhibitions.map((exhibition) => (
              <Exhibitions
                key={exhibition.id}
                id={exhibition.id}
                selectedExhibitionId={selectedExhibitionId}
                setSelectedExhibitionId={setSelectedExhibitionId}
                name={exhibition.name}
                title={exhibition.title}
                type={exhibition.type}
                dateFrom={exhibition.dateFrom}
                dateTo={exhibition.dateTo}
                description={exhibition.description}
                subHeader={exhibition.subHeader}
                descriptionBlock2={exhibition.descriptionBlock2}
                subDescription={exhibition.subDescription}
                listOfPeople={exhibition.listOfPeople}
                pictures={exhibition.pictures}
                pdfFiles={exhibition.pdfFiles}
                staticText={staticText}
                links={exhibition.link}
                language={exhibition.language}
                galleryFiles={exhibition.galleryFiles}
              />
            ))}
          </div>

          <EventsWrapper
            {...{
              currentEvents,
              upcomingEvents,
              previousEvents,
              setSelectedEventId,
              selectedEventId,
              setIsSelectedEvent,
              isSelectedEvent,
              staticText,
            }}
          />

          <About
            about={about}
            isSelectedAbout={isSelectedAbout}
            handleSelectAbout={setIsSelectedAbout}
            staticText={staticText}
          />
        </section>
      </div>
    </main>
  );
};

Main.propTypes = {
  about: object.isRequired,
  selectedExhibitionId: number,
  setSelectedExhibitionId: func.isRequired,
  setIsSidebarOpen: func.isRequired,
  isSelectedAbout: bool.isRequired,
  isSelectedEvent: bool.isRequired,
  setIsSelectedAbout: func.isRequired,
  setIsSelectedEvent: func.isRequired,
  isSelectedLogo: bool.isRequired,
  setIsSelectedLogo: func.isRequired,
  selectedEventId: number,
  setSelectedEventId: func.isRequired,
  currentExhibitions: array,
  previousExhibitions: array,
  currentEvents: array,
  previousEvents: array,
  upcomingExhibitions: array,
  upcomingEvents: array,
  language: string.isRequired,
  setLanguage: func.isRequired,
  staticText: object,
};

Main.defaultProps = {
  selectedExhibitionId: null,
  selectedEventId: null,
  currentExhibitions: [],
  previousExhibitions: [],
  currentEvents: [],
  previousEvents: [],
  upcomingExhibitions: [],
  upcomingEvents: [],
  staticText: {},
};

export default Main;
