import React, { useEffect, useRef, useState } from 'react';
import { object } from 'prop-types';
import Slider from 'react-slick';

import { BASE_URL } from '../../constants';
import { useMedia } from '../../utils/hooks';

import './Gallery.scss';

const Gallery = ({ galleryFiles, staticText }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(null);
  const [exhibitionsContainerClientWidth, setExhibitionsContainerClientWidth] =
    useState(0);

  const galleryItems = galleryFiles?.data ?? [];

  const detailedViewRef = useRef();
  const sliderRef = useRef();

  const exhibitionsContainerElement = document.getElementsByClassName(
    'main__exhibitions-list-container',
  )[0];

  const isMobileVersion = useMedia(
    ['(min-width: 768px)', '(min-width: 280px)'],
    [false, true],
  );

  const handleSlideClick = (e) => {
    if (!sliderRef?.current) return;

    const { clientX, currentTarget } = e;
    const { width, left } = currentTarget.getBoundingClientRect();

    if (clientX - left < width / 2) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      if (exhibitionsContainerElement) {
        setExhibitionsContainerClientWidth(
          exhibitionsContainerElement.clientWidth,
        );
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [exhibitionsContainerElement]);

  return (
    <div className="gallery">
      {currentSlideIndex !== null && (
        <div
          className="gallery__detailed-view"
          ref={detailedViewRef}
          style={{
            width: isMobileVersion ? '100%' : exhibitionsContainerClientWidth,
          }}
        >
          <div className="gallery__detailed-view__slider-container">
            <Slider
              ref={sliderRef}
              className="gallery__detailed-view__slider"
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              afterChange={(currentSlide) => setCurrentSlideIndex(currentSlide)}
              initialSlide={currentSlideIndex || 0}
              arrows={false}
            >
              {galleryItems.map((image) => (
                <div
                  onClick={handleSlideClick}
                  className="gallery__detailed-view__slider__image-container"
                  key={image.id}
                >
                  <img
                    alt={image.attributes.caption}
                    src={BASE_URL + image.attributes.url}
                  />
                </div>
              ))}
            </Slider>
            <div className="gallery__detailed-view__slider-arrows">
              <div
                className="goNext"
                onClick={() => sliderRef?.current?.slickNext()}
              />
              <div
                className="goPrev"
                onClick={() => sliderRef?.current?.slickPrev()}
              />
            </div>
            <div className="gallery__detailed-view__slider-bottom-controls">
              <div>
                {currentSlideIndex + 1}/{galleryItems.length}
              </div>
              <button type="button" onClick={() => setCurrentSlideIndex(null)}>
                {staticText.galleryCloseButton}
              </button>
            </div>
          </div>

          <div className="gallery__detailed-view__slide-details">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  galleryItems[currentSlideIndex]?.attributes?.caption || '',
              }}
            />
            <a
              className="link"
              href={BASE_URL + galleryItems[currentSlideIndex]?.attributes?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {staticText.galleryHighResolutionImage}
            </a>
          </div>
        </div>
      )}

      <ul className="gallery__list">
        {galleryItems.map((image, index) => (
          <li className="gallery__list__item" key={image.id}>
            <button type="button" onClick={() => setCurrentSlideIndex(index)}>
              <img
                alt={image.attributes.caption}
                src={`${BASE_URL}${isMobileVersion ? image.attributes.formats.large.url : image.attributes.formats.small.url}`}
              />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

Gallery.propTypes = {
  galleryFiles: object,
  staticText: object,
};

Gallery.defaultProps = {
  galleryFiles: {},
  staticText: {},
};

export default Gallery;
